import { motion } from "framer-motion";
import { graphql, navigate, useStaticQuery } from "gatsby";
import * as React from "react";
import IntersectionBox from "../animations/IntersectionBox";
import RenderOnIntersectionBox from "../animations/RenderOnIntersectionBox";
import TrailingParagraph from "../animations/TrailingParagraph";
import {
  fadeInFromBottomWithDelay,
  zoomInWithDelay,
} from "../animations/variants";
import Badge from "../components/Badge";
import GetQuoteSection from "../components/GetQuoteSection";
import HowItWorks from "../components/HowItWorks";
import Layout from "../components/layout";
import ManufacturingSolutions from "../components/ManufacturingSolutions";
import MapSection from "../components/MapSection";
import PlatformSection from "../components/PlatformSection";
import RequestCallback from "../components/RequestCallback";
import SEO from "../components/seo";
import TTV from "../components/ttv";

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { eq: "images/machine_img.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      strapi {
        index {
          highlighted_text
          title {
            Title_text
            homepage_banner {
              alternativeText
              url
              previewUrl
            }
          }
          Usp_section {
            logo_title
            logo_image {
              image {
                url
                alternativeText
              }
            }
          }
          client_logos {
            url
            alternativeText
          }
          map_index {
            map_description
            map_title
            map_image {
              alternativeText
              url
            }
          }
          industries_section_heading
          industry_icon_stockimage_name {
            icon {
              image {
                url
                previewUrl
              }
            }
            industry_name
            industry_page_url
          }
          industry_stockimage_static {
            url
            alternativeText
          }
          seo {
            title
            meta_description
            meta_keywords
          }
        }
      }
    }
  `);

  const node = data.strapi.index;

  return (
    <Layout>
      <SEO
        title={node.seo?.title}
        description={node.seo?.meta_description}
        keywords={node.seo?.meta_keywords}
      />
      <div className='container-fluid'>
        <div className='section-1'>
          <div className='row'>
            <IntersectionBox>
              <h1>{node.title.Title_text}</h1>
            </IntersectionBox>
            <div className='content'>
              <IntersectionBox>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className='clippy_button button-1 index-quote-button'
                  onClick={() => {
                    window.open("/contact-us", "_blank");
                  }}>
                  Contact Us
                </motion.button>
              </IntersectionBox>
            </div>
          </div>
          <div className='row'>
            <div className='machine-img col-lg-8 col-md-8 col-sm-8 col-xs-12'>
              <IntersectionBox
                threshold={0.3}
                variants={fadeInFromBottomWithDelay(0.5)}>
                <img
                  src={node.title.homepage_banner.url}
                  alt='Custiv Manufacturing Solutions'
                />
              </IntersectionBox>
            </div>
            <div className='comp-virtue col-lg-4 col-md-4 col-sm-4 col-xs-12'>
              <div className='virtue-list'>
                {node.Usp_section.map((usp, index) => (
                  <div
                    className='virtue fadeInUp'
                    style={{ animationDelay: `${750 + 250 * index}ms` }}>
                    <Badge
                      img={usp.logo_image.image.url}
                      className='index-usp'
                      img_width={index === 0 ? "75%" : "90%"}
                    />
                    {/* <figure>
                      <img src={usp.logo_image.image.publicURL} />
                    </figure> */}
                    <div className='item'>{usp.logo_title}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className='comp-info row'>
            <div className='content'>
              <p className='comp-desc'>
                <TrailingParagraph
                  paragraph={node.highlighted_text}></TrailingParagraph>
              </p>

              <div className='client-logos'>
                <div className='inner'>
                  {node.client_logos.map((logo, index) => (
                    <IntersectionBox
                      threshold={0.9}
                      variants={zoomInWithDelay(0.2)}>
                      <div className='client-logo'>
                        <img src={logo.url} alt='Client Logos' />
                      </div>
                    </IntersectionBox>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <ManufacturingSolutions />
        <TTV />
        <HowItWorks />

        <MapSection
          title={node.map_index.map_title}
          description={node.map_index.map_description}
        />
        <PlatformSection />

        <div className='section-6 container-fluid'>
          <div className='apps-info'>
            <div className='row'>
              <div className='app-details col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                <h2 className='app-head'>
                  {node.industries_section_heading}
                  {/* <TrailingParagraph
                    paragraph={node.industries_section_heading}
                  /> */}
                </h2>
                <div className='row app-list-container'>
                  {node.industry_icon_stockimage_name.map((industry, index) => (
                    <div className='app-list app-1 col-lg-4 col-md-4 col-sm-6 col-xs-6'>
                      <RenderOnIntersectionBox threshold={0.2}>
                        {/* <figure className="app-list-figure">
                          <img src={industry.icon.image.publicURL} />
                        </figure>
                        <p className="app-name"> {industry.industry_name} </p> */}
                        <div
                          onClick={() => {
                            navigate(industry.industry_page_url);
                          }}>
                          <Badge
                            img={industry.icon.image[0].url}
                            text={industry.industry_name}
                            className='applications'
                          />
                        </div>
                      </RenderOnIntersectionBox>
                    </div>
                  ))}
                </div>
              </div>
              <div className='app-img col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                <IntersectionBox>
                  <div className='img-container'>
                    <img
                      src={node.industry_stockimage_static.url}
                      alt='Industries Image'
                    />
                  </div>
                </IntersectionBox>
              </div>
            </div>
          </div>
        </div>
        <RequestCallback />
        <GetQuoteSection />
      </div>
    </Layout>
  );
};

export default IndexPage;

import React from "react";
import TrailingParagraph from "../animations/TrailingParagraph";
import IntersectionBox from "../animations/IntersectionBox";
import RenderOnIntersectionBox from "../animations/RenderOnIntersectionBox";
import {
  zoomInWithDelay,
  fadeInFromBottomWithDelay,
  fadeIn,
  zoomOutWithDelay,
} from "../animations/variants";
import { useStaticQuery, graphql } from "gatsby";
import { useWindowSize } from "react-use";

function HowItWorks() {
  const { height, width } = useWindowSize();

  const data = useStaticQuery(graphql`
    query {
      strapi {
        index {
          illustration {
            illustration_description
            illustration_title
            illustration_image {
              image {
                url
                alternativeText
              }
            }
          }
          process_illustration_heading
        }
      }
    }
  `);

  const node = data.strapi.index;

  return (
    <div className="section-3">
      <div className="content">
        <div className="process-info">
          <h2 className="process-head">
            <TrailingParagraph paragraph={node.process_illustration_heading} />
          </h2>

          <div className="row illus-row">
            {node.illustration.map((step, index) => (
              <div className="process-row col-lg-3 col-md-3 col-sm-6 col-xs-12">
                <RenderOnIntersectionBox
                  variants={zoomInWithDelay(0.2 * index + 0.1)}
                  threshold={0.9}
                >
                  <figure>
                    <img
                      src={step.illustration_image.image.url}
                      className="fadeInUp"
                      style={{
                        animationDelay: `${750 + index * 250}ms`,
                      }}
                      alt={step.illustration_title}
                    />
                  </figure>

                  <div className="step-num-container">
                    <div className="step-num"> {index + 1} </div>
                  </div>
                  <div
                    className={`steps step-${index} fadeInDown`}
                    style={{
                      animationDelay: `${750 + index * 250}ms`,
                    }}
                  >
                    <div className="step-info">
                      <p className="step-head">
                        {/* <TrailingParagraph
                          paragraph={step.illustration_title}
                        /> */}
                        {step.illustration_title}
                      </p>

                      <p className="step-desc">
                        {/* <TrailingParagraph
                          paragraph={step.Illustration_description}
                        /> */}
                        {step.illustration_description}
                      </p>
                    </div>
                  </div>
                </RenderOnIntersectionBox>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HowItWorks;

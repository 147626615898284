import { motion } from "framer-motion";
// import Odometer from "react-odometerjs";
import { Link } from "gatsby";
import React, { useEffect, useState } from "react";
import IntersectionBox from "../animations/IntersectionBox";
import RenderOnIntersectionBox from "../animations/RenderOnIntersectionBox";

function OdometerText() {
  const [currentValue, setCurrentValue] = React.useState(0);

  React.useEffect(() => {
    const odometer = document.getElementById("odometer");
    try {
      const o = new window.Odometer({
        el: odometer,
        value: 0,
        format: "d",
        duration: 200,
      });
      o.update(70);
    } catch (error) {
      odometer.innerHTML = 70;
    }
  }, []);

  return (
    <p className="time-percent">
      <div id="odometer" className="odometer"></div>%
    </p>
  );
}

function TTV() {
  const [currentNumber, setCurrentNumber] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setCurrentNumber(currentNumber + 1);
    }, 100);
  }, []);

  return (
    <div className="section-3 ">
      <div className="content">
        <div className="row time-row">
          <div className="time-img col-lg-6 col-md-6 col-sm-6 col-xs-12">
            <IntersectionBox>
              <img
                src={
                  "https://custiv-website-assets-production.s3.ap-south-1.amazonaws.com/Untitled_design_8_46710ed3fe.png"
                }
                alt="Machine Image"
              />
            </IntersectionBox>
          </div>

          <motion.div className="time-desc col-lg-6 col-md-6 col-sm-6 col-xs-12">
            <RenderOnIntersectionBox>
              <OdometerText />
            </RenderOnIntersectionBox>
            <IntersectionBox>
              <motion.p className="time-head">Faster Manufacturing</motion.p>
            </IntersectionBox>
            <IntersectionBox>
              <motion.p className="time-detail">
                With Custiv, realize the full potential of technology
                <br /> based manufacturing and reduce your time to value by
                almost 70%
              </motion.p>
            </IntersectionBox>
            <IntersectionBox threshold={1}>
              <Link to="/about-us">
                <button className="clippy_button button-2">
                  Read More About Us
                </button>
              </Link>
            </IntersectionBox>
          </motion.div>
        </div>
      </div>
    </div>
  );
}

export default TTV;
